import React from "react";

class Demo extends React.Component{
	render(){
		return (
		<div>
			<p>Hello React</p>
			<p><input type="text" /></p>
			<p id="input-preview"></p>
		</div>
		);
	}

}
export default Demo;
